import revive_payload_client_mVtUPziDMi from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iPhNmBbQfo from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wH2HE8XnpA from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_zNFpWA9e3G from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_699cSt2IDW from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Wo9LqnJQoZ from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WmtD7VaUcb from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3824699768/src/.nuxt/components.plugin.mjs";
import prefetch_client_YhrIDrakWk from "/codebuild/output/src3824699768/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_x3psmpwihy4fm25gdb2ggq27ny/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_ldaa6T8fWy from "/codebuild/output/src3824699768/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.9_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_dXnEIF4cnD from "/codebuild/output/src3824699768/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.9_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_mVtUPziDMi,
  unhead_iPhNmBbQfo,
  router_wH2HE8XnpA,
  payload_client_zNFpWA9e3G,
  navigation_repaint_client_699cSt2IDW,
  check_outdated_build_client_Wo9LqnJQoZ,
  chunk_reload_client_WmtD7VaUcb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YhrIDrakWk,
  switch_locale_path_ssr_ldaa6T8fWy,
  i18n_dXnEIF4cnD
]