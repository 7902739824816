import { default as exampleZA3TAHBoGSMeta } from "/codebuild/output/src3824699768/src/pages/example.vue?macro=true";
import { default as handoffLpgKxo601KMeta } from "/codebuild/output/src3824699768/src/pages/handoff.vue?macro=true";
import { default as index8UpZcx7JUIMeta } from "/codebuild/output/src3824699768/src/pages/index.vue?macro=true";
import { default as indexw5l2lEcPEMMeta } from "/codebuild/output/src3824699768/src/pages/patient/index.vue?macro=true";
import { default as patientB2jljmZTQAMeta } from "/codebuild/output/src3824699768/src/pages/patient.vue?macro=true";
import { default as thank_45youopVYP0lrQNMeta } from "/codebuild/output/src3824699768/src/pages/thank-you.vue?macro=true";
export default [
  {
    name: "example",
    path: "/example",
    component: () => import("/codebuild/output/src3824699768/src/pages/example.vue")
  },
  {
    name: "handoff",
    path: "/handoff",
    component: () => import("/codebuild/output/src3824699768/src/pages/handoff.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3824699768/src/pages/index.vue")
  },
  {
    name: patientB2jljmZTQAMeta?.name,
    path: "/patient",
    component: () => import("/codebuild/output/src3824699768/src/pages/patient.vue"),
    children: [
  {
    name: "patient",
    path: "",
    component: () => import("/codebuild/output/src3824699768/src/pages/patient/index.vue")
  }
]
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/codebuild/output/src3824699768/src/pages/thank-you.vue")
  }
]